import {
    HttpGet,
    HttpPost,
} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from './Settings';

export async function sendLog(error, additionalInfo) {
    return HttpPost(`${Settings.apiUrls.logService}/log/client-error`, {
        applicationId: Settings.appId,
        error: error,
        errorInfo: additionalInfo,
    });
}

export async function getDeltaresUserDetails(deltaresId, cancelToken, cache) {
    const url = `${Settings.apiUrls.singleSignOn}/users/${deltaresId}/details`;

    if (cache?.[url]) return cache[url];
    else if (cache) {
        cache[url] = await getUserDetails(url, cancelToken, cache);
        return cache[url];
    } else getUserDetails(url, cancelToken);
}

function getUserDetails(url, cancelToken, cache = null) {
    return HttpGet(url, cancelToken)
        .then((response) => {
            if (cache) cache[url] = response;
            return response;
        })
        .catch((error) => {
            switch (error.code) {
                case 499:
                    // Canceled request
                    break;
                case 400:
                    sendLog(
                        JSON.stringify(error),
                        `Bad request when fetching "${url}"`
                    );
                    break;
                case 500:
                    sendLog(
                        JSON.stringify(error),
                        `Internal server error while retrieving "${url}"`
                    );
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    sendLog(
                        JSON.stringify(error),
                        `Connection timeout while fetching "${url}"`
                    );
                    break;
                default:
                    sendLog(
                        JSON.stringify(error),
                        `An unknown error occurred while fetching "${url}"`
                    );
                    break;
            }
        });
}
