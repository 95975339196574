import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import 'es6-shim';

import ThemeProvider from 'ibis-design-system/lib/ThemeProvider';
import LoginProvider from 'ibis-design-system/lib/Providers/LoginProvider';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import IEPage, {
    isUsingInternetExplorer,
} from 'ibis-design-system/lib/components/core/IEPage';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';

import * as serviceWorker from './serviceWorker';
import Settings, { SetSettings } from './Settings';
import { sendLog } from './ServerCalls';

const AppContainer = lazy(() => import('./AppContainer'));

if (isUsingInternetExplorer()) {
    ReactDOM.render(<IEPage />, document.getElementById('root'));
} else {
    let settingsPath = 'appSettings.json';
    if (process.env.NODE_ENV === 'development')
        settingsPath = 'appSettings.dev.json';

    HttpGet(`${window.location.origin}/${settingsPath}`)
        .then(response => {
            SetSettings(response);
            ReactDOM.render(
                <ThemeProvider
                //variant={user.hasProfile ? user.profile.theme : null}
                >
                    <LoginProvider
                        api={Settings.apiUrls.sources}
                        ssoApi={Settings.apiUrls.singleSignOn}
                        sso={Settings.uiUrls.singleSignOn}
                        logApi={Settings.apiUrls.logService}
                        returnUrl={window.location.href}
                    >
                        <Suspense
                            fallback={
                                <div style={{ height: '100vh' }}>
                                    <LoadScreen />
                                </div>
                            }
                        >
                            <AppContainer />
                        </Suspense>
                    </LoginProvider>
                </ThemeProvider>,
                document.getElementById('root')
            );
        })
        .catch(error => {
            sendLog(JSON.stringify(error), `Could not load ${settingsPath}`);
        });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
